var dev_code = '0a20g3x1z3';
const localDevServer = 'http://localhost:3000/local';

const Config_dev = {
	'debug': false,
	'aws': {
		'user_pool_id': 'us-east-1_iP4aEGt0G',
		'client_id': '6i7k8ohu9gb1v6f0rfakoq8evf',
		'region': 'us-east-1',
		'identity_pool_id': 'us-east-1:e13fd43e-d861-4b48-9c69-d473f22e6e42',
		'auth_role_arn': 'arn:aws:iam::956931218015:role/Cognito_PostNLAuth_Role',
		'account_id': '956931218015',
		'auth_domain': 'https://postnlews.auth.us-east-1.amazoncognito.com',
	},
	'maps': {
		'api_key': 'AIzaSyCUIjmVjY1UHdjgW-02sB1QWn7iNaNKT0U'
	},
	'base_url' : 'http://localhost:8000',
	'rest': {
		'countries': localDevServer + '/get-countries',
		'incident_types': localDevServer + '/get-incident-types',
		'sources': localDevServer + '/get-sources',
		'incidents': localDevServer + '/get-incidents',
		'incident': localDevServer + '/get-incident',
		'save_incident': localDevServer + '/save-incident',
		'statistics': localDevServer + '/get-statistics',
		'charts': localDevServer + '/get-chart-data',
		'pin_incident': localDevServer + '/pin-incident',
		'layer': localDevServer + '/get-layer',
		'subscribe': localDevServer + '/subscribe',
		'unsubscribe': localDevServer + '/unsubscribe',
		'get_subscriptions': localDevServer + '/get-subscriptions',
		'update_subscriptions': localDevServer + '/update-subscription',
		'get_holidays': localDevServer + '/get-holidays',
		'oauth2_token_exchange': localDevServer + '/oauth2-token',
		'get_storm_layer': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/get_storm_layer',//google maps cannot use local link
		'get_heatmap': localDevServer + '/get-heatmap',
	}
}

const Config_stage = {
	'debug': false,
	'aws': {
		'user_pool_id': 'us-east-1_iP4aEGt0G',
		'client_id': '6i7k8ohu9gb1v6f0rfakoq8evf',
		'region': 'us-east-1',
		'identity_pool_id': 'us-east-1:e13fd43e-d861-4b48-9c69-d473f22e6e42',
		'auth_role_arn': 'arn:aws:iam::956931218015:role/Cognito_PostNLAuth_Role',
		'account_id': '956931218015',
		'auth_domain': 'https://postnlews.auth.us-east-1.amazoncognito.com',
	},
	'maps': {
		'api_key': 'AIzaSyCUIjmVjY1UHdjgW-02sB1QWn7iNaNKT0U'
	},
	'base_url' : 'https://stage.postnlews.nl',
	'rest': {
		'countries': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get_countries',
		'incident_types': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get_incident_types',
		'sources': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get_sources',
		'incidents': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get_incidents',
		'incident': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get_incident',
		'save_incident': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/save_incident',
		'statistics': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get_statistics',
		'charts': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get_chart_data',
		'pin_incident': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/pin_incident',
		'layer': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get_layer',
		'subscribe': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/subscribe',
		'unsubscribe': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/unsubscribe',
		'get_subscriptions': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get-subscriptions',
		'update_subscriptions': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/update-subscription',
		'get_holidays': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get-holidays',
		'oauth2_token_exchange': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/oauth2-token',
		'get_storm_layer': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/get_storm_layer',
		'get_heatmap': 'https://n8a30s7ope.execute-api.us-east-1.amazonaws.com/production/get-heatmap',
	}
}

const Config_prod = {
	'debug': false,
	'aws': {
		'user_pool_id': 'us-east-1_iP4aEGt0G',
		'client_id': '6i7k8ohu9gb1v6f0rfakoq8evf',
		'region': 'us-east-1',
		'identity_pool_id': 'us-east-1:e13fd43e-d861-4b48-9c69-d473f22e6e42',
		'auth_role_arn': 'arn:aws:iam::956931218015:role/Cognito_PostNLAuth_Role',
		'account_id': '956931218015',
		'auth_domain': 'https://postnlews.auth.us-east-1.amazoncognito.com',
	},
	'maps': {
		'api_key': 'AIzaSyCUIjmVjY1UHdjgW-02sB1QWn7iNaNKT0U'
	},
	'base_url' : 'https://www.postnlews.nl',
	'rest': {
		'countries': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_countries',
		'incident_types': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_incident_types',
		'sources': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_sources',
		'incidents': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_incidents',
		'incident': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_incident',
		'save_incident': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/save_incident',
		'statistics': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_statistics',
		'charts': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_chart_data',
		'pin_incident': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/pin_incident',
		'layer': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_layer',
		'subscribe': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/subscribe',
		'unsubscribe': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/unsubscribe',
		'get_subscriptions': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get-subscriptions',
		'update_subscriptions': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/update-subscription',
		'get_holidays': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get-holidays',
		'oauth2_token_exchange': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/oauth2-token',
		'get_storm_layer': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_storm_layer',
		'get_heatmap': 'https://shak0s6na9.execute-api.eu-west-1.amazonaws.com/get_heatmap',
	}
}

export default Config_stage;